import React from 'react';
import {NavigateFunction, useLocation, useNavigate, useSearchParams, Location} from 'react-router-dom';

export interface WithRouter<T = never> { 
    location:Omit<Location, "state"> & {state: T};
    navigate:NavigateFunction;
    searchParams:[URLSearchParams];
}

export function withRouter<T>(Children:any):any {
    return (props: JSX.IntrinsicAttributes) => {
       const ulocation = useLocation();
       const navigate = useNavigate();
       const urlSearchParams = useSearchParams();

       const {state, ...other} = ulocation;

       return <Children {...props} navigate={navigate} location={{state: state as T, ...other}} searchParams={urlSearchParams} />;
    }
}
