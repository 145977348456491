import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';

export const breadcrumbIcon = (key: string) => {
    switch (key) {
        case "/home":
            return (<CottageOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/profile":
            return (<GroupOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/roles":
        case "/role":
            return (<AddTaskOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/users":
        case "/user":
            return (<GroupOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);
        case "/view":
            return (<PictureAsPdfOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

        case "/printroles":
            return (<SummarizeIcon sx={{ mr: .5 }} fontSize="inherit" />);

        default:
            return (<QuestionMarkOutlinedIcon sx={{ mr: .5 }} fontSize="inherit" />);

    }
};