import React from "react";
import {withTheme, WithTheme} from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Breadcrumbs, Theme, Link, Typography } from "@mui/material";
import {AppContext} from '../AppContext';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import { breadcrumbIcon } from './BreadcrumbIcon';

interface BreadcrumbPathProps extends WithTranslation, WithTheme<Theme> {
    crumbs:string[];
    value?: string;
}

class BreadcrumbPath extends React.Component<BreadcrumbPathProps> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
  
    public render () {
        const app = this.context;
        const {t, crumbs, value} = this.props;

        return (
            <Breadcrumbs
            separator={app.lang !== 'ar' ? <NavigateNextIcon fontSize="small" />:<NavigateBeforeOutlinedIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{marginBottom:3}}
            >
                {
                    crumbs.map ((crumb, index) => {
                        if (index < crumbs.length - 1) {
                            return (
                                <Link underline="hover" key={index} color="primary" sx={{cursor:"pointer", marginTop:"1rem"}} onClick={() => app.handleNavigate (crumb, {state: {breadcrumbs:crumbs.slice(0, index)}})}>
                                {breadcrumbIcon (crumb)}
                                {t(crumb)}
                                </Link>
                            );
                        }
                        else {
                            return (<Typography key="2" color="text.disabled">{breadcrumbIcon(crumb)}{value ?? t(crumb)}</Typography>);
                        }
                    })
                }
            </Breadcrumbs>            
        );
    }
}

export default withTranslation()(withTheme<Theme, React.JSXElementConstructor<BreadcrumbPathProps>>(BreadcrumbPath));