import React from "react";
import { styled } from "@mui/material";
import { IconButton, Theme, Collapse, Drawer, Divider, List, ListItem, ListItemText, ListSubheader, ListItemIcon } from "@mui/material";
import {withTheme, WithTheme} from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppContext } from "../AppContext";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import ExplicitOutlinedIcon from '@mui/icons-material/ExplicitOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import AdjustIcon from '@mui/icons-material/Adjust';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import { AccessType, RoleAccess } from "../service/api";

export const drawerWidth = 280;
export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface Menu {
    key:string;
    icon:JSX.Element;
    hidden?: boolean;
    sub?:Menu[];
}

interface SideDrawerProps extends WithTranslation, WithTheme<Theme> {
    
}

interface SideDrawerState {
    open: Map<string, boolean>;
}

class SideDrawer extends React.Component<SideDrawerProps, SideDrawerState> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor (props : SideDrawerProps) {
        super (props);

        this.state = {
            open: new Map ([
                ["menu-invoices", false]
            ])
        };
    }

    handleNavigate = (to: string) => {
        const app = this.context;

        app.setState ({
            search:''
        }, () => {
            app.handleNavigate (to);
        });
    }

    handleOpen = (key:string) => {
        this.setState (prev => {
            const open = new Map (prev.open);

            open.set (key, !open.get(key));

            return ({
                open:open
            });
        });
    }

    handlePin = () => {
        const app = this.context;

        app.setState (prev => ({
            pin: !prev.pin
        }));
    }

    private getAccess = (key:string) : AccessType => {
        const app = this.context;

        if (app.role === 'admin' && ["/users", "/roles"].includes(key)) {
            return AccessType.WRITE;
        }
        else {
            const field = `${app.client ? app.client.toLowerCase():'adc'}_${key.substring(1)}` as keyof RoleAccess;

            const value =  app.getAccess(field)

            return value;
        }
    }

    public render () {
        const {t} = this.props;
        const app = this.context;

        const menus:{[key:string]: {[key:string]:Menu[]}} = {
            "NONTRADE":{
                "MAIN":[
                    {
                        key:`/permenantcustody`,
                        icon:<AutoAwesomeMotionOutlinedIcon/>
                    },
                    {
                        key:`/stpermenantcustodypg`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },        
                    {
                        key:`/stpermenantcustodywadifood`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },        
                    {
                        key:`/stpermenantcustodychevron`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },        
                    {
                        key:`/stpermenantcustodynestle`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'AFB' ? true:false

                    },        
                    {
                        key:`/stpermenantcustodyenergizer`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ASC' ? true:false

                    },        
                    {
                        key:`/stpermenantcustodyjohnson`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ASC' ? true:false

                    },        
                    {
                        key:`/temporarycustody`,
                        icon:<AccountTreeIcon/>
                    },        
                    {
                        key: `/closetemporarycustodypg`,
                        icon: <ChromeReaderModeOutlinedIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },
                    {
                        key: `/closetemporarycustodywadifood`,
                        icon: <ChromeReaderModeOutlinedIcon/>,
                        hidden: app.client !== 'ADC' ? true:false

                    },
                    {
                        key: `/closetemporarycustodychevron`,
                        icon: <ChromeReaderModeOutlinedIcon/>,
                        hidden: app.client !== 'ADC' ? true:false

                    },
                    {
                        key: `/closetemporarycustodyenergizer`,
                        icon: <ChromeReaderModeOutlinedIcon/>,
                        hidden: app.client !== 'ASC' ? true:false

                    },
                    {
                        key: `/closetemporarycustodyjohnson`,
                        icon: <ChromeReaderModeOutlinedIcon/>,
                        hidden: app.client !== 'ASC' ? true:false

                    },
                    {
                        key: `/closetemporarycustodynestle`,
                        icon: <ChromeReaderModeOutlinedIcon/>,
                        hidden: app.client !== 'AFB' ? true:false

                    },
                    {
                        key: `/othersuppliers`,
                        icon: <ExplicitOutlinedIcon/>
                    }
                ]
            },
            
            "SUPPLIERS":{
                "MAIN": [
                    {
                        key:`/mainsupplierspg`,
                        icon:<InventoryIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },
                    {
                        key:`/mainsupplierswadifood`,
                        icon:<LocalShippingIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },
                    {
                        key:`/mainsuppliersjohnson`,
                        icon:<LocalShippingIcon/>,
                        hidden: app.client !== 'ASC' ? true:false
                    },
                    {
                        key:`/mainsuppliersenergizer`,
                        icon:<AdjustIcon/>,
                        hidden: app.client !== 'ASC' ? true:false
                    },
                    {
                        key:`/mainsupplierschevron`,
                        icon:<AccountBalanceOutlinedIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },
                    {
                        key: `/mainsuppliersnestle`,
                        icon: <QrCode2OutlinedIcon/>,
                        hidden: app.client !== 'AFB' ? true:false
                    }
                ]
            },
            "OPERATIONS": {
                "MAIN": [
                    {
                        key:`/prepaidexpenses`,
                        icon:<AccountBalanceOutlinedIcon/>
                    },
                    {
                        key:`/assetcreate`,
                        icon:<AccountBalanceOutlinedIcon/>
                    },
                    {
                        key:`/payroll`,
                        icon:<ReceiptLongIcon/>
                    },
                    {
                        key:`/bounus`,
                        icon:<ReceiptLongIcon/>
                    },
                    {
                        key:`/rundepr`,
                        icon:<ReceiptLongIcon/>
                    },
                    {
                        key:`/monthlyaccrual`,
                        icon:<EventNoteIcon/>
                    },
                    {
                        key:`/ke5z`,
                        icon:<EventNoteIcon/>
                    },
                    {
                        key:`/reportrequest`,
                        icon:<EventNoteIcon/>
                    }
                ]
            },
            "DASHBOARDS": {
                "MAIN": [
                    {
                        key:`/dashboard`,
                        icon:<AccountBalanceOutlinedIcon/>
                    },
                ]
            },
            "SYSTEM": {
                "OPERATOR":[
                    {
                        key:'/users',
                        icon:<GroupOutlinedIcon/>
                     },
                     {
                        key:'/roles',
                        icon:<AddTaskOutlinedIcon/>
                     }   
                ],    
            }
        };

        const menu = menus[app.drawer];

        return (
            <Drawer
                    sx={{
                      width: drawerWidth,
                      flexShrink: 0,
                      position:'relative',
                      pointerEvents: app.open ? 'auto':'none',
                      '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        position:'absolute',
                      }
                    }}
                    variant="persistent"
                    anchor="left"
                    SlideProps={{direction:app.lang !== 'ar' ? 'right':'left'}}
                    open={app.open}
            >
                <DrawerHeader />
                <Divider />
                <List>
                    <ListItem sx={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                        <IconButton aria-label="pin" onClick={this.handlePin} sx={{transform:`rotateZ(${app.pin ? 45:90}deg)`}}> 
                            <PushPinOutlinedIcon />
                        </IconButton>
                    </ListItem>                         
                    {
                        (menu && Object.entries (menu).map ((entry) => {
                            const [key, value] = entry;

                            return  (
                                <React.Fragment key={`section-${key}`}>
                                    <ListSubheader>
                                        <ListItemText>{t(key)}</ListItemText>
                                    </ListSubheader>
                                    {value.map((location:any) => {
                                        if (location.sub) {
                                            return (
                                                ((this.getAccess(location.key) === AccessType.WRITE) && (location.hidden === undefined || location.hidden !== true)) &&
                                            <React.Fragment key={`menu-${location.key}`}>
                                            <ListItem button key={location.key} onClick={() => this.handleOpen (location.key)}>
                                                <ListItemIcon >
                                                {location.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={t(location.key)} />
                                                {this.state.open.get(location.key) ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.open.get(location.key)} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {
                                                        location.sub.map ((subloc:any) => (
                                                            !subloc.hidden &&
                                                            <ListItem button sx={{pl:4}} key={subloc.key} onClick={() => this.handleNavigate (subloc.key)}>
                                                                <ListItemIcon >
                                                                {subloc.icon}
                                                                </ListItemIcon>
                                                                <ListItemText primary={t(subloc.key)} />
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            </Collapse>
                                            </React.Fragment>
                                            );
                                        }
                                        else {
                                            return (
                                                ((this.getAccess(location.key) === AccessType.WRITE) && (location.hidden === undefined || location.hidden !== true)) && <ListItem button key={location.key} onClick={() => this.handleNavigate (location.key)}>
                                                <ListItemIcon >
                                                {location.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={t(location.key)} />
                                            </ListItem>);
                                        }
                                    }
                                    )}                                    
                                </React.Fragment>
                            );
                        }))
                    }
                </List>
            </Drawer>        
        );
    }
}

export default withTranslation()(withTheme<Theme, React.JSXElementConstructor<SideDrawerProps>> (SideDrawer));