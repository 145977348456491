import React, { ChangeEvent, MouseEvent } from "react";
import { styled } from '@mui/material';
import '@fontsource/playfair-display-sc/700.css';
import { withTheme, WithTheme } from "@mui/styles";
import { alpha } from "@mui/system";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Avatar, ListItemAvatar, List, ListItem, CircularProgress, InputBase, AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, ListItemText, ListItemIcon, Divider } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';
import logo from '../images/logo-name.svg';
import { AppContext } from "../AppContext";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { zIndex } from '../common/zindex';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { withRouter, WithRouter } from "../common/router";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + zIndex.appBar
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    left: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            '&:focus': {
                width: '40ch',
            },
        },
        '& .MuiInputBase-inputTypeSearch': {
            backgroundColor: 'red'
        },
    },
}));

interface TopBarProps extends WithTranslation, WithRouter, WithTheme<any> {

}

interface TopBarState {
    toggleSearch: boolean;
    search: string;
    languageEl?: HTMLElement | null;
    anchorEl?: HTMLElement | null;
    clientEl?: HTMLElement | null;
}

const hideTop = [
    "/",
    "/signup",
    "/termsofservice",
    "/forgot",
    "/reset",
    "/verify",
    "/selectorganization"
];

class TopBar extends React.Component<TopBarProps, TopBarState> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: TopBarProps) {
        super(props);

        this.state = {
            toggleSearch: false,
            search: ''
        }
    }

    handleRefresh = () => {
        const app = this.context;

        app.handleToggleSearch();
    };

    handleOpen = (event: MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    private handleLanguageOpen = (event: MouseEvent<HTMLElement>) => {
        this.setState({
            languageEl: event.currentTarget
        });
    };

    private handleLanguageClose = () => {
        this.setState({
            languageEl: null
        });
    };

    private handleClientOpen = (event: MouseEvent<HTMLElement>) => {
        this.setState({
            clientEl: event.currentTarget
        });
    };

    private handleClientClose = () => {
        this.setState({
            clientEl: null
        });
    };


    handleSignOut = () => {
        const app = this.context;

        this.setState({
            anchorEl: null
        }, () => {
            app.handleSignOut();
        });
    };

    handleProfile = () => {
        const app = this.context;

        this.setState({
            anchorEl: null
        }, () => {
            app.handleNavigate("/profile");
        });
    };

    handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const app = this.context;

        if (event.target.value !== undefined && event.target.value.length === 0) {
            app.handleToggleSearch(event.target.value)
        }
        else {
            app.setState({
                search: event.target.value
            });
        }
    };

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const app = this.context;

        if (event.key === 'Enter') {
            event.preventDefault();

            app.handleToggleSearch();
        }
    };

    private handleLanguage = (lang: string) => {
        const app = this.context;

        this.setState({
            languageEl: null
        }, () => app.handleLanguage(lang));
    };

    private handleClient = (client: string) => {
        const app = this.context;

        this.setState({
            clientEl: null
        }, () => app.handleClient(client));
    };


    public componentDidUpdate() {
        const app = this.context;

        if (app.toggleSearch !== this.state.toggleSearch) {
            this.setState({
                toggleSearch: app.toggleSearch,
                search: app.search
            });
        }
    }

    public render() {
        const app = this.context;
        const { t, theme, location } = this.props;

        return (
            <StyledAppBar position="fixed">
                <Toolbar>
                    <IconButton edge="start" sx={{ height: 42 }} color="inherit" aria-label="logo" onClick={app.handleHome}>
                        <img src={logo} style={{ height: 36, margin: 5 }} alt="Logo" />
                    </IconButton>
                    <Typography variant="h5" component="div" sx={{ flexGrow: 1, fontFamily: 'Playfair Display SC', fontWeight: '700', fontSize:'36pt', textAlign:'center' }}>
                        {
                            (app.user_id !== undefined && app.user_id > 0 && 
                                <Typography sx={{fontSize:'2rem'}} >{app.client}</Typography>
                            )
                        }
                    </Typography>
                    {
                        (app.progress && <CircularProgress size="2rem" sx={{ color: 'white', margin: '2px' }} />)
                    }
                    {
                        (app.user_id !== undefined && app.user_id > 0 &&
                            <React.Fragment>
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder={t("Search")}
                                        value={app.search}
                                        onChange={this.handleSearch}
                                        onKeyPress={this.handleKeyPress}
                                        inputProps={{ 'aria-label': 'search' }}
                                        type="search"
                                    />
                                </Search>
                                <IconButton aria-label="Refresh" color="inherit" onClick={this.handleRefresh}>
                                    <CachedIcon />
                                </IconButton>
                            </React.Fragment>
                        )
                    }
                    {
                        (app.user_id !== undefined && app.user_id > 0 &&
                        <React.Fragment>
                        <IconButton aria-label="Client" color="inherit" onClick={this.handleClientOpen}>
                            <BusinessOutlinedIcon />
                        </IconButton>
                        <Menu
                            id="topbar-client-menu"
                            key="topbar-client-menu"
                            anchorEl={this.state.clientEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{ direction: theme.direction }}
                            open={this.state.clientEl ? true : false}
                            onClose={this.handleClientClose}
                        >
                            <MenuItem onClick={() => this.handleClient('ADC')}>
                                <ListItemIcon>
                                    {
                                        (app.client === 'ADC' &&
                                            <CheckOutlinedIcon />
                                        )
                                    }
                                </ListItemIcon>
                                <ListItemText>{t("ADC")}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => { this.handleClient('AFB'); }}>
                                <ListItemIcon>
                                    {
                                        (app.client === 'AFB' &&
                                            <CheckOutlinedIcon />
                                        )
                                    }
                                </ListItemIcon>
                                <ListItemText>{t("AFB")}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => { this.handleClient('ASC'); }}>
                                <ListItemIcon>
                                    {
                                        (app.client === 'ASC' &&
                                            <CheckOutlinedIcon />
                                        )
                                    }
                                </ListItemIcon>
                                <ListItemText>{t("ASC")}</ListItemText>
                            </MenuItem>
                        </Menu>
                    </React.Fragment>)
                    }
                    <React.Fragment>
                        <IconButton aria-label="Account" color="inherit" onClick={this.handleLanguageOpen}>
                            <LanguageOutlinedIcon />
                        </IconButton>
                        <Menu
                            id="topbar-language-menu"
                            key="topbar-language-menu"
                            anchorEl={this.state.languageEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            sx={{ direction: theme.direction }}
                            open={this.state.languageEl ? true : false}
                            onClose={this.handleLanguageClose}
                        >
                            <MenuItem onClick={() => this.handleLanguage('en')}>
                                <ListItemIcon>
                                    {
                                        (app.lang === 'en' &&
                                            <CheckOutlinedIcon />
                                        )
                                    }
                                </ListItemIcon>
                                <ListItemText>{t("English")}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => { this.handleLanguage('ar'); }}>
                                <ListItemIcon>
                                    {
                                        (app.lang === 'ar' &&
                                            <CheckOutlinedIcon />
                                        )
                                    }
                                </ListItemIcon>
                                <ListItemText>{t("Arabic")}</ListItemText>
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                    {
                        (app.user_id !== undefined && app.user_id > 0 &&
                            !hideTop.includes(location.pathname) &&
                            <React.Fragment>
                                <IconButton aria-label="Account" color="inherit" onClick={this.handleOpen}>
                                    <AccountCircleOutlinedIcon />
                                </IconButton>
                                <Menu
                                    id="topbar-menu"
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    sx={{ direction: theme.direction }}
                                    open={this.state.anchorEl ? true : false}
                                    onClose={this.handleClose}
                                >
                                    <List sx={{ flexDirection: 'column', alignItems: 'center', "&:disabled": { opactiy: 1.0 } }}>
                                        <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Avatar sx={{ width: 100, height: 100, backgroundColor: 'white' }}>
                                                <img src={app.user_picture} style={{ width: 100, height: 100 }} alt={t("Picture")} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <ListItemText>{app.user_name}</ListItemText>
                                            <ListItemText>{app.user_email}</ListItemText>
                                        </ListItem>
                                    </List>
                                    <MenuItem onClick={() => this.handleProfile()}>
                                        <ListItemIcon>
                                            <PersonIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{t("Profile")}</ListItemText>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={() => { this.handleSignOut() }}>
                                        <ListItemIcon>
                                            <ExitToAppRoundedIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>{t("Sign Out")}</ListItemText>
                                    </MenuItem>
                                </Menu>
                            </React.Fragment>
                        )
                    }
                </Toolbar>
            </StyledAppBar>
        );
    }
}

export default withTranslation()(withTheme(withRouter(TopBar)));