import React, {useEffect} from 'react';
import {Box, Snackbar, CssBaseline, Alert} from '@mui/material';
import {Routes, Route, useNavigate} from "react-router-dom";
import {AppContext} from '../AppContext';
import { withTheme, WithTheme } from "@mui/styles";
import { styled } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, WithRouter } from "../common/router";
import TopBar from '../components/TopBar';
import SideDrawer, { drawerWidth, DrawerHeader } from '../components/SideDrawer';
import ModulesDrawer from '../components/ModulesDrawer';
import HelpDrawer from '../help/HelpDrawer';
import SignIn from './SignIn';
import Home from './Home';
const Forgot = React.lazy(() => import('./Forgot'));
const Reset = React.lazy(() => import('./Reset'));
const Verify = React.lazy(() => import('./Verify'));
const Users = React.lazy(() => import('./Users'));
const User = React.lazy(() => import('./User'));
const Roles = React.lazy(() => import('./Roles'));
const Role = React.lazy(() => import('./Role'));
const PrintRoles = React.lazy(() => import('../reports/PrintRoles'));
const ReportView = React.lazy(() => import('./ReportView'));
const Profile = React.lazy(() => import('./Profile'));
const ClickUp = React.lazy(() => import('./ClickUp'));


type StyledMainProps = {
  open: boolean;
  lang: string;
}

const StyledMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'lang'})<StyledMainProps>(
  ({ theme, open}) => ({
    flexGrow: 1,
    minHeight: '100vh',
    display:'flex',
    flexDirection:'column',
    pl:0,
    pr:0,
    paddingTop: 0,
    ...{
      marginLeft: `-${drawerWidth}px`,
      marginRight: 0,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    },
  }),
);

const Content = styled('div')`
  position: absolute;
  overflow: auto;
  top:0;
  left:0;
  right:0;
  bottom:0;
  flex-direction:column;
  display:flex;
`;

type RedirectProps = {
  to: string;
}; 

const Redirect = ({ to }: RedirectProps) =>  {
  const navigate = useNavigate();

  useEffect(() => {
      navigate(to);
  });

  return null;
}

interface MainProps extends WithTranslation, WithTheme<any>, WithRouter {
   
}

const hideSidebar = [
  "/",
  "/forgot",
  "/reset",
  "/verify",
];

class Main extends React.Component<MainProps> {
  public static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

    public render () {
        const app = this.context;
        const {theme, location} = this.props;

        return (
        <Box sx={{display:'flex', direction:theme.direction}}>
          <CssBaseline />
            <TopBar />
            <Snackbar
              anchorOrigin={{ horizontal:theme.direction === 'rtl' ? 'left':'right', vertical:'top' }}
              sx={{marginTop:10}}
              open={app.show}
              onClose={app.handleMessageClose}
              autoHideDuration={6000} >
                <Alert onClose={app.handleMessageClose} severity={app.severity} sx={{ width: '100%' }}>
                  { app.message }
                </Alert>
            </Snackbar>      
            {
              (app.user_id &&  !hideSidebar.includes (location.pathname) &&
                <ModulesDrawer /> 
              )
            }
            <SideDrawer />
            <HelpDrawer />
            <StyledMain open={app.open} lang={app.lang}>
                <DrawerHeader />
                <Box sx={{flexGrow:1, position:'relative'}}>
                  <Content>
                    <Routes>
                      <Route path="/" element={<SignIn />} />
                      <Route path="/forgot" element={<Forgot />} />
                      <Route path="/reset" element={<Reset />} />
                      <Route path="/verify" element={<Verify />} />
                      {
                        (app.user_id && 
                          <React.Fragment> 
                            <Route path="/home" element={<Home />} />
                            <Route path="/dashboard" element={<ClickUp src="dashboard" client={app.client}/>} />
                            <Route path="/permenantcustody" element={<ClickUp src="permenantcustody" client={app.client}/>} />
                            <Route path="/stpermenantcustodypg" element={<ClickUp src="stpermenantcustodypg" client={app.client} />} />
                            <Route path="/stpermenantcustodywadifood" element={<ClickUp src="stpermenantcustodywadifood" client={app.client} />} />
                            <Route path="/stpermenantcustodychevron" element={<ClickUp src="stpermenantcustodychevron" client={app.client} />} />
                            <Route path="/stpermenantcustodynestle" element={<ClickUp src="stpermenantcustodynestle" client={app.client} />} />
                            <Route path="/stpermenantcustodyenergizer" element={<ClickUp src="stpermenantcustodyenergizer" client={app.client} />} />
                            <Route path="/stpermenantcustodyjohnson" element={<ClickUp src="stpermenantcustodyjohnson" client={app.client} />} />
                            <Route path="/temporarycustody" element={<ClickUp src="temporarycustody" client={app.client} />} />
                            <Route path="/closetemporarycustodypg" element={<ClickUp src="closetemporarycustodypg" client={app.client} />} />
                            <Route path="/closetemporarycustodywadifood" element={<ClickUp src="closetemporarycustodywadifood" client={app.client} />} />
                            <Route path="/closetemporarycustodychevron" element={<ClickUp src="closetemporarycustodychevron" client={app.client} />} />
                            <Route path="/closetemporarycustodynestle" element={<ClickUp src="closetemporarycustodynestle" client={app.client} />} />
                            <Route path="/closetemporarycustodyenergizer" element={<ClickUp src="closetemporarycustodyenergizer" client={app.client} />} />
                            <Route path="/closetemporarycustodyjohnson" element={<ClickUp src="closetemporarycustodyjohnson" client={app.client} />} />
                            <Route path="/othersuppliers" element={<ClickUp src="othersuppliers" client={app.client} />} />
                            <Route path="/mainsupplierspg" element={<ClickUp src="mainsupplierspg" client={app.client} />} />
                            <Route path="/mainsupplierswadifood" element={<ClickUp src="mainsupplierswadifood" client={app.client} />} />
                            <Route path="/mainsuppliersjohnson" element={<ClickUp src="mainsuppliersjohnson" client={app.client} />} />
                            <Route path="/mainsuppliersenergizer" element={<ClickUp src="mainsuppliersenergizer" client={app.client} />} />
                            <Route path="/mainsupplierschevron" element={<ClickUp src="mainsupplierschevron" client={app.client} />} />
                            <Route path="/mainsuppliersnestle" element={<ClickUp src="mainsuppliersnestle" client={app.client} />} />
                            <Route path="/prepaidexpenses" element={<ClickUp src="prepaidexpenses" client={app.client} />} />
                            <Route path="/assetcreate" element={<ClickUp src="assetcreate" client={app.client} />} />
                            <Route path="/payroll" element={<ClickUp src="payroll" client={app.client} />} />
                            <Route path="/bounus" element={<ClickUp src="bounus" client={app.client} />} />
                            <Route path="/rundepr" element={<ClickUp src="rundepr" client={app.client} />} />
                            <Route path="/monthlyaccrual" element={<ClickUp src="monthlyaccrual" client={app.client} />} />
                            <Route path="/ke5z" element={<ClickUp src="ke5z"  client={app.client}/>} />
                            <Route path="/reportrequest" element={<ClickUp src="reportrequest"  client={app.client}/>} />

                            <Route path="/profile" element={<Profile />} />
                            <Route path="/users" element={<Users />} />
                            <Route path="/user" element={<User />} />
                            <Route path="/roles" element={<Roles />} />
                            <Route path="/role" element={<Role />} />
                            <Route path="/printroles" element={<PrintRoles />} />
                            <Route path="/view" element={<ReportView />} />
                          </React.Fragment>
                        )
                      }
                    <Route path="*" element={<Redirect to="/" />} />
                  </Routes>
                  </Content>
                </Box>
            </StyledMain>
        </Box>
        ); 
    }
}

export default withTranslation ()(withTheme(withRouter(Main)));