import React, { Suspense } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/amiri/400.css';
import '@fontsource/amiri/700.css';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import './App.css';
import i18n from './i18n';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { AppContext, AppContextState } from './AppContext';
import Main from './pages/Main';
import { WithRouter, withRouter } from './common/router';
import { isoDate } from './common/formats';
import {RoleAccess, AccessType} from './service/api'

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
});

const cacheLtr = createCache({
    key: 'muiltr'
});

const theme = createTheme({
    palette: {
        primary: {
            light: '#e6eefc',
            main: '#437fe6',
            dark: '#1D62D9',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#ffffff',
            main: '#1e66e1',
            dark: '#c9b2ba',
            contrastText: '#000000'
        }
    },
    components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 'bold'
            },
          },
        },
        MuiTab: {
            styleOverrides: {
              root: {
                fontWeight: 'bold'
              },
            },
          },
          MuiBreadcrumbs: {
            styleOverrides: {
              root: {
                fontWeight: 'bold'
              },
            },
          },      },
    direction: 'ltr'
});



class App extends React.Component<WithRouter, AppContextState> {

    constructor(props: WithRouter) {
        super(props);

        this.state = {
            user_id: 0,
            lang: 'en',
            client: 'ADC',
            role: 'user',
            show: false,
            severity: 'success',
            message: '',
            open: false,
            pin: false,
            isHelpOpen: false,
            toggleSearch: false,
            search: '',
            drawer: 'general ledger',
            organizations:[],
            routes: {},
            handleHome: this.handleHome,
            handleNavigate: this.handleNavigate,
            handleHelpDrawer: this.handleHelpDrawer,
            handleSignOut: this.handleSignOut,
            handleMessageClose: this.handleMessageClose,
            showMessage: this.showMessage,
            setState: this.setAppState,
            showProgress: this.showProgress,
            handleSearch: this.handleSearch,
            handleToggleSearch: this.handleToggleSearch,
            handleLanguage: this.handleLanguage,
            handleClient: this.handleClient,
            saveState: this.saveState,
            findState: this.findState,
            getAccess: this.getAccess,
        }
    }

    public componentDidMount(): void {
        const value = sessionStorage.getItem("app.state");

        if (value) {
            const app = JSON.parse(value);

            this.setState({
                lang: app.lang,
                user_id: app.user_id,
                user_name: app.user_name,
                user_email: app.user_email,
                user_picture: app.user_picture,
                organization_id: app.organization_id,
                organization_name: app.organization_name,
                organizations: app.organizations,
                client: app.client,
                role: app.role,
                access: app.access,
                currency_code: app.currency_code,
                currency_symbol: app.currency_symbol,
                fraction_digits: app.fraction_digits,
                gl_account_pattern: app.gl_account_pattern,
                costcenter_pattern: app.costcenter_pattern,
                financial_years: app.financial_years,
                financial_year_id: app.financial_year_id,
                page_size: app.page_size,
                page_width: app.page_width,
                page_height: app.page_height,
                paper_size: app.paper_size,
                show: app.show,
                severity: app.severity,
                message: app.message,
                open: app.open,
                pin: app.pin,
                isHelpOpen: app.isHelpOpen,
                toggleSearch: app.toggleSearch,
                progress: app.progress,
                search: app.search,
                drawer: app.drawer,
                routes: app.routes
            });

        }
    }



    private handleNavigate = (path: string, options?: any) => {
        const { navigate } = this.props;

        this.setState({
            open: !this.state.pin ? false : true
        }, () => {
            this.saveSession();
            
            navigate(path, options);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    private saveState = <K extends unknown>(pathname: string, state: K, fn?: () => void) => {
        const replacer = (key: string, value: any): any => {
            const anydata:any = state;
            if (anydata[key] && anydata[key] instanceof Date) {
                return isoDate(anydata[key]);
            }
            return value;
        };

        sessionStorage.setItem(`app.route:${pathname}`, 
            JSON.stringify(state, replacer));

        if (fn) {
            fn ();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    private findState = <K extends unknown>(pathname: string): K => {
        const value = sessionStorage.getItem(`app.route:${pathname}`);

        return value ? JSON.parse(value):undefined; 
    };

    private getAccess = (field: keyof RoleAccess): AccessType => {
        let result = AccessType.NONE;

        if (this.state.access) {
            result = this.state.access[field];
        }
        return result;
    };


    private handleHelpDrawer = (open: boolean, help?: JSX.Element, fn?: () => void) => {
        this.setState({
            isHelpOpen: open,
            help: help
        }, () => {
            this.saveSession();

            if (fn) {
                fn();
            }
        });
    };

    private handleToggleSearch = (search?: string, callback?: () => void) => {
        this.setState((previous) => ({
            search: search ?? previous.search,
            toggleSearch: !previous.toggleSearch
        }), () => {
            this.saveSession ();
            if (callback) {
                callback ();
            }
        });
    };

    private handleSignOut = () => {
        this.setState({
            user_id: 0,
            user_name: undefined,
            user_email: undefined,
            user_picture: undefined,
            organization_id: undefined,
            organization_name: undefined,
            organizations:undefined,
            currency_code:undefined,
            currency_symbol:undefined,
            fraction_digits:undefined,
            gl_account_pattern:undefined,
            costcenter_pattern: undefined,
            financial_years: undefined,
            financial_year_id: undefined,
            page_size: undefined,
            page_width: undefined,
            page_height: undefined,
            paper_size: undefined,
            show: true,
            severity: 'success',
            message: i18n.t('Signed Out'),
            open: false
        }, () => {
            this.saveSession ();
        });
    };

    private handleMessageClose = () => {
        this.setState({
            show: false,
            message: '',
            severity: 'success'
        }, () => {
            this.saveSession ();
        });
    };

    private handleHome = () => {
        this.handleNavigate(this.state.user_id ? `/home` : '/');
    };

    private showMessage = (msg: any, fn?: () => void) => {
        this.setState({
            show: true,
            ...msg
        }, () => {
            this.saveSession ();

            if (fn) {
                fn ();
            }
        }
        );
    };

    private showProgress = (show: boolean, fn?: () => void) => {
        this.setState({
            progress: show
        }, () => {
            this.saveSession ();

            if (fn) {
                fn ();
            }
        }
        );
    };

    private handleSearch = (search: string, callback?: () => void) => {
        this.setState({
            search: search
        }, () => {
            this.saveSession ();
            if (callback) {
                callback ();
            }
        });
    };


    private handleLanguage = (lang: string, fn?: () => void) => {
        i18n.changeLanguage(lang, () => {
            this.setState({
                lang: lang
            }, () => {
                this.saveSession ();
                
                if (fn) {
                    fn ();
                }
            });
        });
    };

    private handleClient = (client: string, fn?: () => void) => {
        this.setState({
            client: client
        }, () => {
            this.saveSession ();

            this.handleNavigate("/home");
            
            if (fn) {
                fn ();
            }
        });
    };

    private saveSession() {
        sessionStorage.setItem("app.state", JSON.stringify({
            lang: this.state.lang,
            user_id: this.state.user_id,
            user_name: this.state.user_name,
            user_email: this.state.user_email,
            user_picture: this.state.user_picture,
            organization_id: this.state.organization_id,
            organization_name: this.state.organization_name,
            organizations: this.state.organizations,
            client: this.state.client,
            role: this.state.role,
            access: this.state.access,
            currency_code: this.state.currency_code,
            currency_symbol: this.state.currency_symbol,
            fraction_digits: this.state.fraction_digits,
            gl_account_pattern: this.state.gl_account_pattern,
            costcenter_pattern: this.state.costcenter_pattern,
            financial_years: this.state.financial_years,
            financial_year_id: this.state.financial_year_id,
            page_size: this.state.page_size,
            page_width: this.state.page_width,
            page_height: this.state.page_height,
            paper_size: this.state.paper_size,
            show: this.state.show,
            severity: this.state.severity,
            message: this.state.message,
            open: this.state.open,
            pin: this.state.pin,
            isHelpOpen: this.state.isHelpOpen,
            toggleSearch: this.state.toggleSearch,
            progress: this.state.progress,
            search: this.state.search,
            drawer: this.state.drawer,
            routes: this.state.routes
        }));
    }

    private setAppState = <K extends keyof AppContextState>(state: ((prevState: Readonly<AppContextState>, props: Readonly<any>) => (Pick<AppContextState, K> | AppContextState | null)) | (Pick<AppContextState, K> | AppContextState | null), callback?: () => void): void => {
        this.setState(state,
            () => {
                this.saveSession();

                if (callback) {
                    callback();
                }
            });
    }

    public render() {
        return (
            <AppContext.Provider value={this.state}>
                <CacheProvider value={this.state.lang === 'en' ? cacheLtr : cacheRtl}>
                    <ThemeProvider theme={theme}>
                        <Suspense fallback="Loading...">
                            <Main />
                        </Suspense>
                    </ThemeProvider>
                </CacheProvider>
            </AppContext.Provider>
        );
    }
}

export default withRouter(App);
