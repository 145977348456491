import React from "react";
import { styled } from "@mui/material";
import { withTheme, WithTheme } from '@mui/styles';
import { Theme, Drawer, Divider, List, ListItemButton, ListItem } from "@mui/material";
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppContext } from "../AppContext";
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { zIndex } from '../common/zindex';
import { ModulesEnum } from "../common/types";


export const drawerWidth = 280;

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + zIndex.modulesDrawer,
    '& .MuiDrawer-paper': {
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
        boxSizing: 'border-box',
    }
}));


interface ModulesProps extends WithTranslation, WithTheme<Theme> {

}

interface SideDrawerState {
    open: Map<string, boolean>;
}

class ModulesDrawer extends React.Component<ModulesProps, SideDrawerState> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: ModulesProps) {
        super(props);

        this.state = {
            open: new Map([
                ["menu-invoices", false]
            ])
        };
    }

    private handleNavigate = (to: string) => {
        const app = this.context;

        app.setState({
            search: '',
            open: false,
            drawer: ''
        }, () => {
            app.handleNavigate(to);
        });
    }

    private handleToggleOpen = (key: string) => {
        const app = this.context;

        app.setState(previous => ({
            open: (key !== previous.drawer ? true : !previous.open),
            drawer: key
        }));
    }

    public render() {
        const app = this.context;

        return (
            <StyledDrawer
                variant="permanent"
                anchor="left"
                SlideProps={{ direction: app.lang !== 'ar' ? 'right' : 'left' }}
                open={app.user_id ? true : false}

            >
                <DrawerHeader />
                <Divider />
                {
                    (app.organization_id === 0 &&
                        <List>
                            <ListItem key={"businessaccounts"} onClick={() => this.handleNavigate("/businessaccounts")} disablePadding disableGutters>
                                <ListItemButton>
                                    <BusinessOutlinedIcon/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={"users"} onClick={() => this.handleNavigate("/users")} disablePadding disableGutters>
                                <ListItemButton>
                                    <GroupOutlinedIcon/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    )
                }
                {
                    (app.organization_id !== 0 &&
                        <List>
                            <ListItem key={"home"} onClick={() => this.handleNavigate(`/home`)} disablePadding disableGutters>
                                <ListItemButton>
                                    <CottageOutlinedIcon />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={ModulesEnum.NONTRADE} onClick={() => this.handleToggleOpen(ModulesEnum.NONTRADE)} selected={app.drawer === ModulesEnum.NONTRADE && app.open} disablePadding disableGutters>
                                <ListItemButton>
                                    <AccountBalanceOutlinedIcon />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={ModulesEnum.SUPPLIERS} onClick={() => this.handleToggleOpen(ModulesEnum.SUPPLIERS)} selected={app.drawer === ModulesEnum.SUPPLIERS && app.open} disablePadding disableGutters>
                                <ListItemButton>
                                    <DomainOutlinedIcon />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={ModulesEnum.OPERATIONS} onClick={() => this.handleToggleOpen(ModulesEnum.OPERATIONS)} selected={app.drawer === ModulesEnum.OPERATIONS && app.open} disablePadding disableGutters>
                                <ListItemButton>
                                    <AddCardOutlinedIcon />
                                </ListItemButton>
                            </ListItem>
                            <ListItem key={ModulesEnum.DASHBOARD} onClick={() => this.handleToggleOpen(ModulesEnum.DASHBOARD)} selected={app.drawer === ModulesEnum.DASHBOARD && app.open} disablePadding disableGutters>
                                <ListItemButton>
                                    <DashboardIcon />
                                </ListItemButton>
                            </ListItem>
                            {
                                (app.role === 'admin' &&
                                    <ListItem key={ModulesEnum.SYSTEM} onClick={() => this.handleToggleOpen(ModulesEnum.SYSTEM)} selected={app.drawer === ModulesEnum.SYSTEM && app.open} disablePadding disableGutters>
                                        <ListItemButton>
                                            <SettingsOutlinedIcon />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            }
                        </List>
                    )
                }
            </StyledDrawer>
        );
    }
}

export default withTranslation()(withTheme<Theme, React.JSXElementConstructor<ModulesProps>>(ModulesDrawer));